<template>
    <div>
        <vx-card title="Supplier Payment">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Apply">
                    <div class="con-tab-ejemplo">
                        <apply/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Waiting Reconcile">
                    <div class="con-tab-ejemplo">
                        <reconcile />
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Waiting Reverse">
                    <div class="con-tab-ejemplo">
                        <waiting />
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'primary'" label="Reconciled">
                    <div class="con-tab-ejemplo">
                        <reconciled />
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Reversed">
                    <div class="con-tab-ejemplo">
                        <reversed/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Cancel">
                    <div class="con-tab-ejemplo">
                        <cancel/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'primary'" label="Supplier Deposit">
                    <div class="con-tab-ejemplo">
                        <supplierDeposit :collectionDate="null" :depositDate="null"/>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open/open.vue";
import apply from "./apply/apply.vue";
import cancel from "./cancel/cancel.vue";
import reconcile from "./reconcile/reconcile.vue";
import reconciled from "./reconciled/reconcile.vue";
import reversed from "./reversed/reversed.vue";
import supplierDeposit from "./supplier_deposit/index.vue";
import waiting from "./waiting/waiting.vue";
// import done from "./done.vue";

export default {
    components: {
        open,
        apply,
        confirm,
        cancel,
        reconcile,
        reversed,
        reconciled,
        waiting,
        supplierDeposit
        // done,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>